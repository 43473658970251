@import "node_modules/bootstrap/scss/bootstrap";

p {
white-space: pre-wrap;
}
.rounded-bottom {
    border-bottom-right-radius: 0.6rem!important;
    border-bottom-left-radius: 0.6rem!important;
}
.gallery img {
object-fit: cover;
max-width: 100%;
}
.bg-guy{
    background-color:#8cb86c;
}
@media only screen and (min-width: 500px) {
body {
background-image: url(../images/background-photo.jpg);
background-position: center center;
background-repeat: no-repeat;
background-attachment: fixed;
background-size: cover;
background-color: #464646;
}
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 20px;
}
}
@media only screen and (min-width: 767px) {
.grid-4{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}
}
